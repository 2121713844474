import Vue from 'vue'
import axios from "axios"
import VueAxios from "vue-axios"
// import 'element-ui/lib/theme-chalk/index.css'
import { Container, Header, Main, Footer, Row, Col, Pagination, Table, TableColumn, Form, FormItem, Menu, MenuItem, Button, Input,Checkbox } from 'element-ui';
// import { Decimal } from "decimal";
import App from './sea.vue'

Vue.config.productionTip = false
Vue.use(VueAxios, axios);
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://service.ce-am.com/api';
// axios.defaults.baseURL = 'http://localhost:3000/api';
Vue.component(Container.name, Container);
Vue.component(Header.name, Header);
// Vue.component(Aside.name, Aside);
Vue.component(Main.name, Main);
Vue.component(Footer.name, Footer);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
// Vue.component(Submenu.name, Submenu);
Vue.component(Pagination.name, Pagination);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
Vue.component(Checkbox.name, Checkbox);
new Vue({
  render: h => h(App),
}).$mount('#app')
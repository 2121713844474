<style lang="scss">
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<template>
  <el-container>
    <el-header>
      <el-menu
        :default-active="activeMenu"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="/sea.html"><a>公海查询</a></el-menu-item>
        <el-menu-item index="/river.html">
          <a href="/river.html">私域查询</a>
        </el-menu-item>
        <el-menu-item index="/stat.html" style="color: #f00">
          <a href="/stat.html">查询统计</a>
        </el-menu-item>
        <el-menu-item index="/mall.html">
          <a href="/mall.html">商城统计</a>
        </el-menu-item>
        <el-menu-item index="/consume.html" style="color: #f00">
          <a href="/consume.html">商城消费统计</a>
        </el-menu-item>
        <el-menu-item index="/purse.html" style="color: #f00">
          <a href="/purse.html">提现审批</a>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-container>
      <el-main>
        <el-row type="flex" justify="space-between">
          <el-col>当前查询总数:{{ total }}</el-col>
          <el-col>
            <el-checkbox v-model="wait" @change="fetch">待还>0</el-checkbox>
            <el-checkbox v-model="diff" @change="fetch">充提差>0</el-checkbox>
          </el-col>
          <el-col>
            <el-input
              size="mini"
              placeholder="输入搜索"
              clearable
              v-model="keyword"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="search"
              ></el-button>
            </el-input>
          </el-col>
          <el-col>
            <el-pagination
              background
              :page-size="50"
              layout="prev, pager, next"
              :total="total"
              :current-page.sync="page"
              @current-change="fetch"
            >
            </el-pagination>
          </el-col>
        </el-row>
        <el-table
          height="750"
          stripe
          style="width: 100%"
          :data="list"
          row-key="id"
          @sort-change="sorting"
          @filter-change="filtering"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="用户ID">
                  <span>{{ props.row.id }}</span>
                </el-form-item>
                <el-form-item label="用户名">
                  <span>{{ props.row.username }}</span>
                </el-form-item>
                <el-form-item label="性别">
                  <span>{{
                    props.row.gender == 1
                      ? "男"
                      : props.row.gender == 2
                      ? "女"
                      : "未知"
                  }}</span>
                </el-form-item>
                <el-form-item label="证件号">
                  <span>{{ props.row.identity }}</span>
                </el-form-item>
                <el-form-item label="资产余额">
                  <span>{{ props.row.assets }}</span>
                </el-form-item>
                <el-form-item label="待收利息">
                  <span>{{ props.row.waitInterest }}</span>
                </el-form-item>
                <el-form-item label="累计充值">
                  <span>{{ props.row.totalRecharge }}</span>
                </el-form-item>
                <el-form-item label="累计提现">
                  <span>{{ props.row.totalWithdrawal }}</span>
                </el-form-item>
                <template v-if="props.row['user.status'] !== null">
                  <el-form-item label="绑定时间">
                    <span>{{ datetime(props.row["user.bindTime"]) }}</span>
                  </el-form-item>
                  <el-button type="text" size="small">冻结</el-button>
                </template>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="name"> </el-table-column>
          <el-table-column label="手机" prop="phone"> </el-table-column>
          <el-table-column
            label="待回本金"
            prop="waitCapital"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column label="充提差" prop="difference" sortable="custom">
          </el-table-column>
          <el-table-column label="加入ITZ" prop="regTime" sortable="custom">
            <template slot-scope="scope">
              <p>{{ datetime(scope.row.regTime) }}</p>
            </template>
          </el-table-column>
          <el-table-column
            label="JSZ计划"
            column-key="isKeeper"
            :filters="[
              { text: '2期', value: 2 },
              { text: '1期', value: 1 },
              { text: '未加入', value: 0 },
            ]"
          >
            <template slot-scope="scope">
              <p>
                {{ keeperStatus(scope.row["isKeeper"]) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="JSZ状态"
            column-key="user.status"
            :filters="[
              { text: '已绑定未激活', value: 0 },
              { text: '已激活', value: 1 },
              { text: '未绑定', value: -1 },
            ]"
          >
            <template slot-scope="scope">
              <p>{{ status(scope.row) }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <el-footer>
      <el-row type="flex" justify="center">Version 0.2</el-row>
    </el-footer>
  </el-container>
</template> 
<script>
export default {
  name: "sea",
  data() {
    return {
      activeMenu: window.location.pathname,
      list: [],
      total: 0,
      page: 1,
      sort: null,
      filter: null,
      keyword: "",
      wait: false,
      diff: false,
    };
  },

  computed: {},

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const params = {
          page: this.page,
        };
        if (this.sort) params.sort = this.sort;
        if (this.filter) params.filter = this.filter;
        if (this.wait) params.wait = this.wait;
        if (this.diff) params.diff = this.diff;
        this.keyword = this.keyword.trim();
        if (this.keyword) params.keyword = this.keyword;
        const result = await this.axios.get("/sea/list", {
          params,
        });
        const data = result.data.data;
        this.list = data.list;
        this.total = data.total;
      } catch (error) {
        console.log("获取用户信息失败:", error);
      }
    },

    keeperStatus: function (isKeeper) {
      switch (isKeeper) {
        case 1:
          return "1期";
        case 2:
          return "2期";
        default:
          return "未加入";
      }
    },

    status: function (row) {
      if (row["user.status"] === null) return "未绑定";
      switch (row["user.status"]) {
        case 0:
          return "绑定未激活";
        case 1:
          return "已激活";
        default:
          return "其他";
      }
    },

    datetime: function (time) {
      if (!time) return "";
      var d = new Date(time * 1000);
      return [d.getFullYear(), d.getMonth() + 1, d.getDate()].join("-");
    },

    paging() {
      this.fetch();
    },

    sorting({ prop, order }) {
      console.log("sorting", prop, order);
      if (!prop) return;
      this.page = 1;
      switch (order) {
        case "ascending":
          order = "ASC";
          this.sort = { prop, order };
          break;
        case "descending":
          order = "DESC";
          this.sort = { prop, order };
          break;
        default:
          this.sort = null;
          break;
      }
      this.fetch();
    },

    filtering(term) {
      const key = Object.keys(term)[0];
      if (term[key].length == 0) {
        if (this.filter !== null) {
          if (Object.prototype.hasOwnProperty.call(this.filter, key))
            delete this.filter[key];
          if (Object.keys(this.filter) == 0) this.filter = null;
        }
      } else {
        if (this.filter === null) this.filter = {};
        this.filter[key] = term[key];
      }
      this.page = 1;
      this.fetch();
    },

    search() {
      this.page = 1;
      this.fetch();
    },
  },
};
</script>